import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { fade } from '@material-ui/core/styles/colorManipulator';
import Button from "@material-ui/core/Button";


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        alignItems: "center",
        flex: 1,
    },
    fixedHeight: {
        height: 'calc(100vh - 180px)',
        position: 'relative'
    },
    headerBox: {
      height: 80,
      backgroundColor: fade(theme.palette.primary.light, 0.3),
      width: '100%',
      display: 'flex',
    },
    header: {
      color: "#000000",
      fontSize: 16,
      fontWeight: 550
    },
    Content: {
      height: '100%',
      opacity: 0.7,
      maxWidth: '100%',
      position: "absolute",
      margin:0,
      padding: 0,
    },
    Overlay: {
      backgroundColor: fade(theme.palette.primary.dark, 0.8),
      height: '100%',
      width: '100%',
      position:'relative'
    },
    ContentHeader: {
      color: "#000000",
      fontSize: 40,
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(8),
      position: 'relative',
      fontWeight: 500
    },
    ContentSubtitle: {
      color: "#000000",
      fontSize: 28,
      paddingTop: theme.spacing(0),
      paddingLeft: theme.spacing(8),
      position: 'relative',

    },
    ButtonStyle: {
      marginLeft: theme.spacing(8),
      marginTop: theme.spacing(4),
      color: theme.palette.primary.main,
      backgroundColor: "#FFFFFF"
    }

}));

const PageNotFound = (props) => {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const subtitle = ( props.subtitle == undefined) ? "Η σελίδα που αναζητήσατε δε βρέθηκε" : props.subtitle;
    const buttonContent = ( props.buttonContent == undefined ) ? "Μεταβαση στην αρχικη" : props.buttonContent;
    const redirectURL = ( props.redirectURL == undefined ) ? "/home" : props.redirectURL;

    return (
        <Container id= "container-not-found" maxWidth="xl" className={classes.container}>
            <Grid id="grid-not-found" item xs={12} md={12} lg={12} >
                <Paper className={fixedHeightPaper} elevation={0}>
                  <Box justifyContent="center" alignItems="center" id="header" xs={12} md={12} lg={12} className={classes.headerBox}>
                    <Typography
                            align="center"
                            justify="center"
                            margin="auto"
                            className={classes.header}
                          >
                            404 ΣΦΑΛΜΑ ΣΕΛΙΔΑΣ
                    </Typography>
                  </Box>
                  <Box className={classes.Overlay}>
                    <img src="/404Page.png" id="image" xs={12} md={12} lg={12} className={classes.Content} />     
                    <Typography elevation={3}
                                    className={classes.ContentHeader}
                    >
                                    Ωωωωωχ...
                    </Typography>
                    <Typography elevation={3}
                                    className={classes.ContentSubtitle}
                    >
                                    {subtitle}
                    </Typography>
                    <Button variant="contained" href={redirectURL} className={classes.ButtonStyle}>
                      {buttonContent}
                    </Button>
                  </Box>
                </Paper>
            </Grid>
        </Container>
    );
};


export default PageNotFound;